import { useHead } from "@unhead/vue";

export default function useSeoHead() {
  const { baseUrl, path, fullUrl } = useCanonicalUrl();

  useHead({
    meta: [
      {
        name: "x-ua-compatible",
        content: "IE=edge",
      },
    ],
    link: [
      {
        /* important to have this hard-coded to main link so that
         * incase the crawler reaches the subdomains that won't be an issue
         */
        href: fullUrl.value,
        rel: "canonical",
      },
    ],
    templateParams: {
      schemaOrg: {
        host: baseUrl,
        path,
        inLanguage: "de-DE",
      },
    },
    script: [
      {
        type: "application/ld+json",
        innerHTML: JSON.stringify({
          "@context": "https://schema.org",
          "@type": "WebSite",
          url: "https://lexmea.de/",
          potentialAction: {
            "@type": "SearchAction",
            target: {
              "@type": "EntryPoint",
              urlTemplate:
                "https://api.lexmea.de/api/legalTextParagraph/search?query={search_term_string}",
            },
            "query-input": "required name=search_term_string",
          },
        }),
      },
    ],
  });
}
